import styled from "styled-components";

const reportProblemModalBody = styled.div`
  .report-problem-table {
    margin-bottom: 24px;
  }
`;

const skuId = styled.div`
  text-align: left;
`;

export default {
  reportProblemModalBody,
  skuId,
};
