import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const login = styled.section`
  background-color: ${COLOR.bgColor_1};
  border-radius: 16px;
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.12);
  display: flex;
  position: relative;
  width: 800px;
  height: 506px;

  .left-side {
    padding: 40px 24px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    h2 {
      ${setFontStyle("Head4", "Bold")};
      color: ${COLOR.primaryBlue};
      margin-bottom: 40px;
    }

    > form {
      width: 100%;

      .input-text {
        margin-bottom: 40px;
      }
    }
  }

  .right-side {
    background-color: ${COLOR.primaryBlue_10};
    border-radius: 0 16px 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    .fullfillment {
      width: 320px;
    }
    .logo {
      position: absolute;
      bottom: 16px;
      right: 19px;
    }
  }
`;

const background = styled.div`
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLOR.bk_60};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  login,
  background,
};
