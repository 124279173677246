import styled from "styled-components";

const pageBreakWrapper = styled.div`
  && {
    page-break-after: always;
  }
`;

const container = styled.div`
  padding: 0.5cm;
  font-size: 10pt;

  > .header {
    margin-bottom: 0.5cm;
    display: flex;
    justify-content: space-between;

    > .barcode-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > .code {
        font-size: 11pt;
        font-weight: 700;
      }

      > .barcode {
        width: 10cm;
        height: 1.5cm;
      }
    }

    > .packing-no {
      font-size: 12pt;
      font-weight: 700;
    }
  }

  > .order-info,
  .code {
    text-align: center;
    font-size: 11pt;
    margin-bottom: 0.3cm;
  }

  > table {
    width: 100%;

    th,
    td {
      border: 1px solid;
      padding: 8px;
    }

    th:first-child,
    th:last-child {
      width: 2cm;
    }
  }
`;

export default {
  pageBreakWrapper,
  container,
};
