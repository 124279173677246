import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const invoiceSummary = styled.div`
  > .message {
    margin-bottom: 16px;
    text-align: left;
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_800};
  }

  > .invoice-summary {
    padding: 16px 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: ${COLOR.primaryBlue_10};
    ${setFontStyle("SubHead3")};
    color: ${COLOR.grayScale_700};
  }

  > .total-count {
    margin-bottom: 8px;
    text-align: right;
    ${setFontStyle("SubHead3")};
    color: ${COLOR.bk};
  }
`;

const printInvoiceTableWrapper = styled.div<{
  isVisibleScrollbar: boolean;
}>`
  margin-bottom: 24px;

  > .table {
    tbody {
      display: block;
      overflow: auto;
      max-height: 285px;

      ${({ isVisibleScrollbar }) =>
        isVisibleScrollbar &&
        css`
          &::-webkit-scrollbar {
            display: block;
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: ${COLOR.grayScale_400};
          }
          &::-webkit-scrollbar-track {
            background-color: ${COLOR.white};
          }
        `}
    }
  }
`;

const actionButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  > .barcode-button {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .barcode {
      width: 100%;
      height: 56px;
    }
  }
`;

export default {
  invoiceSummary,
  printInvoiceTableWrapper,
  actionButtons,
};
