import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_ATOMS } from "@sellernote/_shared/src/states/fulfillment/auth";

export default function useLogin() {
  // TODO: 로그인 유지 안되게 해야함

  const history = useHistory();

  const loggedIn = useRecoilValue(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  useEffect(() => {
    if (loggedIn) history.replace("/packing");
  }, [history, loggedIn]);
}
