import { memo } from "react";

import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import Styled from "./index.styles";

function CountForScanning({
  canOpenDirectInputModal,
  currentQty,
  quantity,
  openDirectInputModal,
}: {
  canOpenDirectInputModal: boolean;
  currentQty: number | undefined;
  quantity: number;
  openDirectInputModal: () => void;
}) {
  return (
    <>
      <Styled.count>
        {getStyledSKUCount({ currentCount: currentQty, goalCount: quantity })}

        <TextButton
          label="직접 입력"
          theme="withIcon"
          icon="arrowRight"
          size="14px"
          handleClick={openDirectInputModal}
          disabled={!canOpenDirectInputModal}
        />
      </Styled.count>
    </>
  );
}

export default memo(CountForScanning);
