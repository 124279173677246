import styled from "styled-components";

const invoiceDirectInputModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  > .input-text {
    margin-bottom: 56px;
  }
`;

export default {
  invoiceDirectInputModalBody,
};
