import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > .title {
    background-color: ${COLOR.grayScale_200};
    text-align: center;
    border-radius: 4px 4px 0 0;
    padding: 8px 0;
    ${setFontStyle("SubHead3", "Bold")};
    color: ${COLOR.grayScale_700};
  }

  > .video {
    height: 120px;
    background-color: ${COLOR.bk};
  }
`;

export default {
  container,
};
