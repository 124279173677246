import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const StrongStyle = css`
  ${setFontStyle("Head6", "Bold")};
`;

const statusBar = styled.div<{ isWorking: boolean }>`
  height: 40px;
  padding: 8px 24px;
  color: ${COLOR.white};
  display: flex;
  justify-content: space-between;

  ${({ isWorking }) =>
    isWorking
      ? css`
          background-color: ${COLOR.pointWarning};
        `
      : css`
          background-color: ${COLOR.pointSuccess};
        `};
`;

const workingStatus = styled.strong`
  ${StrongStyle}
`;

const workingInfo = styled.div`
  display: flex;
  align-items: center;

  > strong {
    ${StrongStyle}
  }

  > .separator {
    margin: 0 10px;
  }

  /** 디자인 요구사항에 맞추기 위해 커스텀 */
  > .custom-text-button {
    margin-left: 10px;
    em {
      color: ${COLOR.white};
    }
  }
`;

const clock = styled.span`
  ${setFontStyle("Head6")};
`;

export default {
  statusBar,
  workingStatus,
  workingInfo,
  clock,
};
