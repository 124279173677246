import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function useFetchOutSidePackageOfShipda() {
  const {
    ResponseHandler: ResponseHandlerOfGettingOutSidePackageListOfShipda,
    initConfirmModalStatus:
      initConfirmModalStatusOfGettingOutSidePackageListOfShipda,
  } = PACKING_QUERY.useGetOutsidePackageListOfShipda();

  useScanActionBarcode({
    actionBarcode: "H_GET_SHI_OUT",
    actionFn: initConfirmModalStatusOfGettingOutSidePackageListOfShipda,
  });

  return {
    ResponseHandlerOfGettingOutSidePackageListOfShipda,
  };
}
