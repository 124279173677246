import styled, { css } from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const invoiceDetail = styled.div<{ isVisibleScrollbar: boolean }>`
  display: flex;
  flex-direction: column;

  > .total-count {
    padding: 8px 0;
    text-align: center;
    background-color: ${COLOR.primaryBlue_10};
    ${setFontStyle("SubHead3", "Bold")};
    border-bottom: 1px solid ${COLOR.primaryBlue_40};
  }

  .invoice-detail-table {
    tbody {
      display: block;
      height: 275px;
      overflow: auto;

      ${({ isVisibleScrollbar }) =>
        isVisibleScrollbar &&
        css`
          &::-webkit-scrollbar {
            display: block;
            width: 4px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: ${COLOR.grayScale_400};
          }
          &::-webkit-scrollbar-track {
            background-color: ${COLOR.white};
          }
        `}

      /** 불필요하게 2줄에 나뉘는걸 막기 위해 커스텀 */
      td {
        word-break: break-all !important;
      }
    }
  }
`;

export default {
  invoiceDetail,
};
