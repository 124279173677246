import { useCallback, useMemo } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import useResetPackingStates from "@sellernote/_shared/src/hooks/fulfillment/useResetPackingStates";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

import Styled from "./index.styles";

export default function useCanceledInvoice() {
  const canceledInvoiceNo = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );
  const resetCanceledInvoiceNo = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );

  const { resetPackingStates } = useResetPackingStates();

  const handleCanceledInvoiceModalConfirm = useCallback(() => {
    resetCanceledInvoiceNo();

    resetPackingStates();
  }, [resetCanceledInvoiceNo, resetPackingStates]);

  const CanceledInvoiceModal = useMemo(
    () => (
      <Modal
        usePortal
        uiType="contentWithCustomBody"
        active={!!canceledInvoiceNo}
        title={`${canceledInvoiceNo} (송장(QR)번호)`}
        body={
          <Styled.canceledInvoiceModalBody>
            <div className="description">
              해당 건은 <strong>주문이 취소</strong>되었습니다.
              <br />
              매니저에게 안내 바랍니다.
            </div>

            <div className="warning-message">
              *확인버튼을 누르면 취소시점 담당자로 지정됩니다.
              <br />
              재입고 진행 전 까지 상품을 잘 보관해주세요.
            </div>

            <div className="barcode-action">
              <Barcode
                value="O_CAN_INV"
                options={{ displayValue: false, margin: 0 }}
              />
              <Button
                theme="secondary"
                size="block"
                label="확인"
                handleClick={(e) => {
                  e.stopPropagation();
                  handleCanceledInvoiceModalConfirm();
                }}
              />
            </div>
          </Styled.canceledInvoiceModalBody>
        }
      />
    ),
    [canceledInvoiceNo, handleCanceledInvoiceModalConfirm]
  );

  useScanActionBarcode({
    actionBarcode: "O_CAN_INV",
    actionFn: handleCanceledInvoiceModalConfirm,
  });

  return { CanceledInvoiceModal };
}
