import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > .title {
    text-align: center;
    padding: 4px 0;
    background-color: ${COLOR.grayScale_200};
    color: ${COLOR.grayScale_700};
    ${setFontStyle("SubHead3", "Bold")};
    border-bottom: 1px solid ${COLOR.grayScale_400};
  }

  > .detail {
    height: 40px;
    display: flex;
    align-items: center;

    > .out-side-package {
      width: 80px;
      padding: 8px 4px;
      background-color: ${COLOR.grayScale_200};
      color: ${COLOR.grayScale_700};
      ${setFontStyle("SubHead3", "Bold")};
    }

    > .temp-provider {
      width: 96px;
      height: 100%;
      background-color: ${COLOR.white};
    }

    > .temp-out-side-package-list {
      width: 224px;
      height: 100%;
      background-color: ${COLOR.grayScale_100};
    }

    /** 디자인 요구사항으로 사이즈 커스텀 */
    > .custom-input-select {
      height: 40px;
      .disabled {
        cursor: not-allowed;

        > .value {
          &.has-value {
            color: ${COLOR.grayScale_400};
          }
        }
      }

      > div {
        height: 100%;
        padding: 8px;
        border: 0;
      }
    }
  }
`;

export default {
  container,
};
