import { memo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { getWorkerNameByAuthority } from "@sellernote/_shared/src/utils/fulfillment/common";
import TextButton from "@sellernote/_sds-v1/src/components/button/TextButton";

import useClock from "./useClock";
import useLogout from "./useLogout";
import Styled from "./index.styles";

function WorkingStatus() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

  return (
    <Styled.workingStatus>
      {isWorking ? "작업 중" : "작업 대기"}
    </Styled.workingStatus>
  );
}

function WorkingInfo() {
  const currentManager = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );

  const { handleLogout } = useLogout();

  return (
    <Styled.workingInfo className="right-container">
      <strong>
        {getWorkerNameByAuthority(currentManager)} (
        {`${currentManager?.deviceName ?? ""} 작업대`})
      </strong>

      <span className="separator">|</span>

      <Clock />

      <TextButton
        className="custom-text-button"
        label="로그아웃 >"
        theme="default"
        size="14px"
        handleClick={handleLogout}
      />
    </Styled.workingInfo>
  );
}

function Clock() {
  const currentTime = useClock();

  return <Styled.clock>{currentTime}</Styled.clock>;
}

function StatusBar() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);

  return (
    <Styled.statusBar isWorking={isWorking}>
      <WorkingStatus />
      <WorkingInfo />
    </Styled.statusBar>
  );
}

export default memo(StatusBar);
