import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import usePrintInBrowser from "@sellernote/_shared/src/hooks/fulfillment/usePrintInBrowser";
import { FULFILLMENT_PACKING_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/packing";

import withRequireAuth from "hocs/withRequireAuth";

import PackingLabel from "./PackingLabel";

function Print() {
  const packingListToPrintPackingLabel = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.PACKING_LIST_TO_PRINT_PACKING_LABEL
  );

  const history = useHistory();

  const { PageToPrint, handlePrint } = usePrintInBrowser({
    type: "16X10",
    list: packingListToPrintPackingLabel.map((item) => (
      <PackingLabel key={item.packingNo} packingLabelData={item} />
    )),
    onAfterPrint: history.goBack,
  });

  useEffect(() => {
    handlePrint();
  }, [handlePrint]);

  return <>{PageToPrint}</>;
}

export default withRequireAuth(Print);
