import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";

import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import Toast from "@sellernote/_sds-v2/src/components/Toast";

export default function useScanCompletedInvoice() {
  const [isCompletedInvoice, setIsCompletedInvoice] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_COMPLETED_INVOICE
  );

  const handleCompletedInvoiceToastClose = useCallback(
    () => setIsCompletedInvoice(false),
    [setIsCompletedInvoice]
  );

  const CompletedInvoiceToast = useMemo(
    () =>
      isCompletedInvoice && (
        <Toast
          uiType="messageWithIcon"
          status="negative"
          message="패킹이 완료된 송장입니다."
          reset={handleCompletedInvoiceToastClose}
        />
      ),
    [handleCompletedInvoiceToastClose, isCompletedInvoice]
  );

  return { CompletedInvoiceToast };
}
