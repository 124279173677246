import { useEffect, useState } from "react";

import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

const geCurrentTime = () => toFormattedDate(new Date(), "YYYY.MM.DD.HH:mm");

export default function useClock() {
  const [currentTime, setCurrentTime] = useState(geCurrentTime);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(geCurrentTime());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  return currentTime;
}
