import { useCallback, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { FULFILLMENT_AUTH_SELECTORS } from "@sellernote/_shared/src/states/fulfillment/auth";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { PackingProblemDict } from "@sellernote/_shared/src/types/fulfillment/packing";
import { getWorkerNameByAuthority } from "@sellernote/_shared/src/utils/fulfillment/common";
import { sortListByItemNameAndSkuId } from "@sellernote/_shared/src/utils/fulfillment/packing";

export default function useReportProblem() {
  const [isVisibleReportProblemModal, setIsVisibleReportProblemModal] =
    useState(false);

  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const currentUser = useRecoilValue(
    FULFILLMENT_AUTH_SELECTORS.CURRENT_MANAGER
  );
  const invoiceDetailList = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const setPackingProblemDict = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PROBLEM_DICT
  );
  const resetPackingProblemDict = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PROBLEM_DICT
  );

  const handleReportProblemModalOpen = useCallback(() => {
    const problemList = invoiceDetailList.filter(
      (item) => item.currentQty !== item.quantity
    );
    const sortedList = sortListByItemNameAndSkuId(problemList);

    setPackingProblemDict(
      sortedList.reduce<PackingProblemDict>(
        (prevList, item) => ({
          ...prevList,
          [item.skuId]: {
            skuBarcode: item.skuBarcode,
            currentQty: item.currentQty,
            quantity: item.quantity,
            workerName: getWorkerNameByAuthority(currentUser),

            locationId: item.locationId,
            wmsStatus: "packing",
            workerId: currentUser?.id ?? 0,
            kind: undefined,
          },
        }),
        {}
      )
    );

    setIsVisibleReportProblemModal(true);
  }, [currentUser, invoiceDetailList, setPackingProblemDict]);

  const handleReportProblemModalClose = useCallback(() => {
    resetPackingProblemDict();

    setIsVisibleReportProblemModal(false);
  }, [resetPackingProblemDict]);

  return {
    isVisibleReportProblemModal,
    handleReportProblemModalOpen,
    handleReportProblemModalClose,

    isWorking,
  };
}
