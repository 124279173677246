import { memo } from "react";
import { useRecoilValue } from "recoil";

import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import {
  OutSidePackageProviderValue,
  OutSidePackageValue,
} from "@sellernote/_shared/src/types/fulfillment/packing";
import { providerOptionDict } from "@sellernote/_shared/src/utils/fulfillment/packing";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";

import useOutSidePackage from "./useOutSidePackage";
import Styled from "./index.styles";

const providerOptionList = Object.values(providerOptionDict);

function OutSidePackageTitle() {
  const selectedPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );

  return <div className="title">{`포장${selectedPackingNo}`}</div>;
}

function OutSidePackageDetail() {
  const isWorking = useRecoilValue(FULFILLMENT_PACKING_ATOMS.IS_WORKING);
  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );

  return (
    <div className="detail">
      <div className="out-side-package">외포장</div>
      {!isWorking && !isStatusOfScanningCompletedInvoice ? (
        <TempOutSidePackageDetail />
      ) : (
        <OutSidePackageInputSelects />
      )}
    </div>
  );
}

function TempOutSidePackageDetail() {
  return (
    <>
      <div className="temp-provider" />
      <div className="temp-out-side-package-list" />
    </>
  );
}

function OutSidePackageInputSelects() {
  const {
    isSelectableList,

    provider,
    handleProviderSelect,

    outSidePackageOptionList,
    outSidePackageItem,
    handleOutSidePackageSelect,

    ResponseHandlerOfGettingOutSidePackageListOfCustomer,
  } = useOutSidePackage();

  return (
    <>
      <InputSelect<OutSidePackageProviderValue>
        className="custom-input-select"
        uiType="outline"
        optionList={providerOptionList}
        selectedOption={provider}
        handleSelect={handleProviderSelect}
        placeholder=""
        width="96px"
        disabled={!isSelectableList}
      />
      {provider?.value !== "none" ? (
        <InputSelect<OutSidePackageValue>
          className="custom-input-select"
          uiType="filled"
          optionList={outSidePackageOptionList}
          selectedOption={outSidePackageItem}
          handleSelect={handleOutSidePackageSelect}
          placeholder="(선택)"
          width="224px"
          disabled={!isSelectableList}
        />
      ) : (
        <div className="temp-out-side-package-list" />
      )}

      {ResponseHandlerOfGettingOutSidePackageListOfCustomer}
    </>
  );
}

function OutSidePackage() {
  return (
    <Styled.container>
      <OutSidePackageTitle />
      <OutSidePackageDetail />
    </Styled.container>
  );
}

export default memo(OutSidePackage);
