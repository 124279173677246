import { atom } from "recoil";

const KEY_PREFIX = "boful-worker-web/packing/atoms";

export type WarningSoundType = "overCount" | "mismatch";
const WARNING_SOUND_TRIGGER_BY_TYPE = atom<WarningSoundType | undefined>({
  key: `${KEY_PREFIX}/WARNING_SOUND_TRIGGER_BY_TYPE`,
  default: undefined,
});

/**
 * 모달이 열린 상태에는 해당 모달과 관련된 스캔만 처리하도록 현재 열린 모달의 바코드 값(바코드가 없는 모달은 'modal-type')을 관리
 */
const OPENED_MODAL_VALUE_LIST = atom<string[]>({
  key: `${KEY_PREFIX}/OPENED_MODAL_VALUE_LIST`,
  default: [],
});

export default {
  WARNING_SOUND_TRIGGER_BY_TYPE,
  OPENED_MODAL_VALUE_LIST,
};
