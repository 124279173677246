import React, { memo } from "react";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { CLASS_OF_MODAL_WITHOUT_BARCODE } from "@sellernote/_shared/src/constants/fulfillment/packing";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";

import CompleteButton from "./CompleteButton";
import ProblemListTable from "./ProblemListTable";
import Styled from "./index.styles";

function ReportProblemModal({
  setRowInfoToHighlight,
  handleReportProblemModalClose,
}: {
  setRowInfoToHighlight: React.Dispatch<
    React.SetStateAction<TableRowInfoToHighlight | undefined>
  >;
  handleReportProblemModalClose: () => void;
}) {
  return (
    <Modal
      uiType="contentWithCustomBody"
      active={true}
      title="문제가 발생한 상품(들)을 선택해주세요."
      body={
        <Styled.reportProblemModalBody>
          <ProblemListTable />
          <CompleteButton
            setRowInfoToHighlight={setRowInfoToHighlight}
            handleReportProblemModalClose={handleReportProblemModalClose}
          />
        </Styled.reportProblemModalBody>
      }
      onClose={handleReportProblemModalClose}
      usePortal
      data-modal-type="report-problem"
      className={CLASS_OF_MODAL_WITHOUT_BARCODE}
    />
  );
}

export default memo(ReportProblemModal);
