import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { PACKING_ATOMS } from "states/packing";
import { WarningSoundType } from "states/packing/atoms";

const WARNING_SOUND_FILE_SRC: Record<WarningSoundType, string> = {
  overCount: "over-count-warning.mp3",
  mismatch: "mismatch-warning.mp3",
};

export default function WarningAudio() {
  const warningSoundTriggerByType = useRecoilValue(
    PACKING_ATOMS.WARNING_SOUND_TRIGGER_BY_TYPE
  );

  const warningAudioRef = useCallback(
    (node: HTMLAudioElement | null) => {
      if (!warningSoundTriggerByType) {
        if (!node) {
          return;
        }

        node.pause();
        node.currentTime = 0;
        return;
      }

      if (!node) {
        return;
      }

      node.play();
    },
    [warningSoundTriggerByType]
  );

  if (!warningSoundTriggerByType) {
    return null;
  }

  return (
    <audio
      src={WARNING_SOUND_FILE_SRC[warningSoundTriggerByType]}
      ref={warningAudioRef}
      loop
    />
  );
}
