import { memo, useState } from "react";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

import PrintInvoiceModal from "../PrintInvoiceModal";
import Styled from "./index.styles";

function PrintInvoice() {
  const [isVisiblePrintInvoiceModal, setIsVisiblePrintInvoiceModal] =
    useState(false);

  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );
  const { shippingId } = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );

  const {
    mutate: updateItemNameForPrintingInvoice,
    initConfirmModalStatus:
      initConfirmModalStatusOfUpdatingItemNameForPrintingInvoice,
    ResponseHandler: ResponseHandlerOfUpdatingItemNameForPrintingInvoice,
  } = PACKING_QUERY.useUpdateItemNameForPrintingInvoice({
    shippingId,
    type: "printInvoice",
  });

  useScanActionBarcode({
    actionBarcode: "H_UPD_NAME",
    actionFn: initConfirmModalStatusOfUpdatingItemNameForPrintingInvoice,
  });

  const handleItemNameUpdate = () => {
    updateItemNameForPrintingInvoice(undefined, {
      onSuccess: () => {
        setIsVisiblePrintInvoiceModal(true);
      },
    });
  };

  return (
    <>
      <Styled.printInvoiceButtonWrapper>
        <Button
          theme="secondary"
          size="block"
          label="송장(QR)수동출력"
          handleClick={handleItemNameUpdate}
          disabled={!isStatusOfScanningCompletedInvoice}
        />
      </Styled.printInvoiceButtonWrapper>

      <PrintInvoiceModal
        active={isVisiblePrintInvoiceModal}
        onClose={() => setIsVisiblePrintInvoiceModal(false)}
      />

      {ResponseHandlerOfUpdatingItemNameForPrintingInvoice}
    </>
  );
}

export default memo(PrintInvoice);
