import styled from "styled-components";

const container = styled.div`
  margin-bottom: 24px;

  /** 디자인에 맞추기 위해서 커스텀 */
  .custom-list-item {
    .value {
      overflow: auto;
    }
  }
`;

export default {
  container,
};
