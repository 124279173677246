import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const requestMemo = styled.div`
  display: flex;
  flex-direction: column;
`;

const requestMemoTitle = styled.div`
  padding: 16px;
  background-color: ${COLOR.grayScale_200};
  ${setFontStyle("SubHead3", "Bold")};
  color: ${COLOR.grayScale_700};
  border-bottom: 1px solid ${COLOR.grayScale_400};
`;

const requestMemoContents = styled.div`
  min-height: 54px;
  padding: 16px;
  ${setFontStyle("Body7")};
  color: ${COLOR.grayScale_700};
  border-bottom: 1px solid ${COLOR.grayScale_200};
`;

export default {
  requestMemo,
  requestMemoTitle,
  requestMemoContents,
};
