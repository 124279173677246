import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { PACKING_ATOMS } from "states/packing";

import Modal from "@sellernote/_shared/src/componentsToMoveToV1/Modal";
import { TableRowInfoToHighlight } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import SCAN_QUERY from "@sellernote/_shared/src/queries/fulfillment/SCAN_QUERY";
import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";
import {
  getFormattedSingleSkuId,
  getSKUIdFromSKUBarcode,
} from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import {
  getDetailOfSku,
  getInvoiceDetailListOfDuplicateBarcode,
} from "@sellernote/_shared/src/utils/fulfillment/packing";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

import useSelectDuplicateBarcode from "./useSelectDuplicateBarcode";
import useSetSkuScanningResult from "./useSetSkuScanningResult";

export interface ErrorMessageOfScanningSku {
  title: React.ReactNode;
  body?: React.ReactNode;
}

export default function useScanSku({
  setRowInfoToHighlight,
}: {
  setRowInfoToHighlight: Dispatch<
    SetStateAction<TableRowInfoToHighlight | undefined>
  >;
}) {
  const [errorMessageOfScanningSku, setErrorMessageOfScanningSku] =
    useState<ErrorMessageOfScanningSku>();

  const invoiceDetailList = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const [isScannedSkuIdComplete, setIsScannedSkuIdComplete] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_SCANNED_SKU_ID_COMPLETE
  );
  const invoiceNoOfFirstPacking = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_FIRST_PACKING
  );
  const setCanceledInvoiceNo = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.CANCELED_INVOICE_NO
  );
  const [warningSoundTriggerByType, setWarningSoundTriggerByType] =
    useRecoilState(PACKING_ATOMS.WARNING_SOUND_TRIGGER_BY_TYPE);

  const {
    mutate: interpretBarcode,
    initConfirmModalStatus: initConfirmModalStatusOfCheckingCancelingInvoice,
    ResponseHandler: ResponseHandlerOfCheckingCancelingInvoice,
  } = SCAN_QUERY.useInterpretBarcode<"shipping">({
    isToCheckForCanceling: true,
    isBarcodeModal: true,
  });

  const {
    handleConfirmSelectionModalOpen,

    ResultHandlerOfSelectingDuplicateBarcode,
  } = useSelectDuplicateBarcode({ setRowInfoToHighlight });

  const { setSkuScanningResult, CountAudio } = useSetSkuScanningResult({
    setRowInfoToHighlight,
  });

  // ! 취소관리 위한 임시 확인용
  useEffect(() => {
    if (isScannedSkuIdComplete) {
      interpretBarcode(
        {
          type: "shipping",
          barCode: invoiceNoOfFirstPacking,
        },
        {
          onSuccess: ({ data: { shippingStatus } }) => {
            if (shippingStatus === "cancel") {
              setCanceledInvoiceNo(invoiceNoOfFirstPacking);
            }

            setIsScannedSkuIdComplete(false);
          },
        }
      );
    }
  }, [
    invoiceNoOfFirstPacking,
    interpretBarcode,
    isScannedSkuIdComplete,
    setCanceledInvoiceNo,
    setIsScannedSkuIdComplete,
  ]);

  const checkIsCorrectSkuBarcode = useCallback(
    (scannedBarcode: string) => {
      if (warningSoundTriggerByType) {
        return;
      }

      const scannedSkuId = getSKUIdFromSKUBarcode(scannedBarcode);
      const invoiceDetailOfScannedResult = getDetailOfSku({
        list: invoiceDetailList,
        skuId: scannedSkuId,
        skuBarcode: scannedBarcode,
      });

      if (!invoiceDetailOfScannedResult) {
        setErrorMessageOfScanningSku({
          title: `스캔된 코드는 패킹 리스트에 없습니다.`,
        });
        setWarningSoundTriggerByType("mismatch");
        return;
      }

      const invoiceDetailListOfDuplicateBarcode =
        getInvoiceDetailListOfDuplicateBarcode({
          invoiceDetailList,
          scannedBarcode,
        });
      const hasDuplicateBarcode =
        invoiceDetailListOfDuplicateBarcode.length > 1;
      if (hasDuplicateBarcode) {
        const isAllCompleteDuplicateBarcode =
          invoiceDetailListOfDuplicateBarcode.every(
            (invoiceDetailItem) =>
              invoiceDetailItem.currentQty === invoiceDetailItem.quantity
          );
        if (isAllCompleteDuplicateBarcode) {
          setErrorMessageOfScanningSku({
            title: `최대 스캔수량을 초과하였습니다.`,
            body: "다시 확인해주세요.",
          });
          setWarningSoundTriggerByType("overCount");
          return;
        }

        handleConfirmSelectionModalOpen(scannedBarcode);
        return;
      }

      const isCompleted =
        invoiceDetailOfScannedResult.currentQty ===
        invoiceDetailOfScannedResult.quantity;
      if (isCompleted) {
        setErrorMessageOfScanningSku({
          title: (
            <>
              {getFormattedSingleSkuId(invoiceDetailOfScannedResult.skuId)}(SKU
              ID)
              <br />
              최대 스캔수량을 초과하였습니다.
            </>
          ),
          body: "다시 확인해주세요.",
        });
        setWarningSoundTriggerByType("overCount");
        return;
      }

      setSkuScanningResult({
        invoiceDetailOfScannedResult,
        scannedSkuId,
        scannedBarcode,
      });
    },
    [
      warningSoundTriggerByType,
      invoiceDetailList,
      setSkuScanningResult,
      setWarningSoundTriggerByType,
      handleConfirmSelectionModalOpen,
    ]
  );

  const ErrorMessageOfScanningSkuModal = useMemo(
    () => (
      <Modal
        usePortal
        barcodeValues={{ actionPositive: "O_SCAN_SKU" }}
        uiType={errorMessageOfScanningSku?.body ? "content" : "titleOnly"}
        active={!!errorMessageOfScanningSku}
        title={errorMessageOfScanningSku?.title}
        body={errorMessageOfScanningSku?.body}
        actionPositive={{
          label: "확인",
          handleClick: () => {
            setErrorMessageOfScanningSku(undefined);
            setWarningSoundTriggerByType(undefined);
          },
        }}
      />
    ),
    [errorMessageOfScanningSku, setWarningSoundTriggerByType]
  );

  useScanActionBarcode({
    actionBarcode: "O_SCAN_SKU",
    actionFn: () => {
      setErrorMessageOfScanningSku(undefined);
      setWarningSoundTriggerByType(undefined);
    },
  });
  useScanActionBarcode({
    actionBarcode: "H_INT_BAR",
    actionFn: initConfirmModalStatusOfCheckingCancelingInvoice,
  });

  return {
    checkIsCorrectSkuBarcode,

    ErrorMessageOfScanningSkuModal,
    ResponseHandlerOfCheckingCancelingInvoice,
    ResultHandlerOfSelectingDuplicateBarcode,

    CountAudio,
  };
}
