import { useCallback, useMemo, useState } from "react";

import { LOGIN_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/auth";
import AUTH_QUERY from "@sellernote/_shared/src/queries/fulfillment/AUTH_QUERY";

export default function useLoginForm() {
  const [{ accountId, deviceId, password }, setLoginFormState] =
    useState<LOGIN_REQ>({
      accountId: "",
      deviceId: 0,
      password: "",
    });

  const { mutate: login, ResponseHandler: ResponseHandlerOfLogin } =
    AUTH_QUERY.useWorkerLogin();

  const handleLoginFormChange = useCallback(
    <ValueType extends LOGIN_REQ[keyof LOGIN_REQ]>(key: keyof LOGIN_REQ) =>
      (value: ValueType) =>
        setLoginFormState((prevLoginFormState) => ({
          ...prevLoginFormState,
          [key]: value,
        })),
    [setLoginFormState]
  );

  const isAllInputValid = useMemo(
    () => !!accountId && !!deviceId && !!password,
    [accountId, deviceId, password]
  );

  const handleLoginSubmit = useCallback(() => {
    login({ accountId, deviceId, password });
  }, [accountId, deviceId, login, password]);

  return {
    accountId,
    deviceId,
    password,
    handleLoginFormChange,

    isAllInputValid,

    handleLoginSubmit,

    ResponseHandlerOfLogin,
  };
}
