import styled from "styled-components";

const completePacking = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .barcode {
    width: 100%;
    height: 56px;
  }
`;

export default {
  completePacking,
};
