import { useEffect } from "react";
import { useRecoilState } from "recoil";

import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { ActionBarcodeType } from "@sellernote/_shared/src/types/fulfillment/packing";

/**
 * 패킹화면에 있는 바코드를 스캔했을 때 해당하는 동작을 실행하기 위해 사용
 */
export default function useScanActionBarcode({
  actionBarcode,
  actionFn,
}: {
  actionBarcode: Exclude<ActionBarcodeType, undefined>;
  actionFn: () => void;
}) {
  const [currentActionBarcode, setCurrentActionBarcode] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.CURRENT_ACTION_BARCODE
  );

  useEffect(() => {
    if (currentActionBarcode === actionBarcode) {
      actionFn();

      setCurrentActionBarcode(undefined);
    }
  }, [actionFn, currentActionBarcode, actionBarcode, setCurrentActionBarcode]);
}
