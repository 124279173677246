import { useMemo } from "react";
import { useRecoilState } from "recoil";

import { TableDataListItem } from "@sellernote/_shared/src/headlessComponents/table/useTable";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";
import { PackingProblem } from "@sellernote/_shared/src/types/fulfillment/packing";
import { BOFUL_PROBLEM_OPTION_LIST } from "@sellernote/_shared/src/utils/fulfillment/constants";
import { getFormattedSingleSkuId } from "@sellernote/_shared/src/utils/fulfillment/fulfillment";
import { getStyledSKUCount } from "@sellernote/_shared/src/utils/fulfillment/sku";
import InputSelect from "@sellernote/_sds-v1/src/components/input/InputSelect";
import InputTextArea from "@sellernote/_sds-v1/src/components/input/InputTextArea";

import { ReportProblemTableItem } from "./ProblemListTable";
import Styled from "./index.styles";

export default function useProblemListTable() {
  const [problemDict, setProblemDict] = useRecoilState(
    FULFILLMENT_PACKING_ATOMS.PROBLEM_DICT
  );

  const problemTableList: TableDataListItem<ReportProblemTableItem>[] = useMemo(
    () =>
      Object.entries(problemDict).flatMap(([skuId, packingProblem]) => {
        const hasDirectInput = packingProblem.kind?.value === "directInput";

        const selectProblemRow: TableDataListItem<ReportProblemTableItem> = {
          rowKey: skuId,

          noBorderBottom: hasDirectInput,

          skuId: (
            <Styled.skuId>
              {getFormattedSingleSkuId(Number(skuId))}
              <br />
              {packingProblem.skuBarcode
                ? `(${packingProblem.skuBarcode})`
                : ""}
            </Styled.skuId>
          ),

          count: getStyledSKUCount({
            currentCount: packingProblem.currentQty,
            goalCount: packingProblem.quantity,
          }),

          workerName: packingProblem.workerName,

          problem: (
            <InputSelect<PackingProblem>
              placeholder="선택"
              uiType="outline"
              optionList={BOFUL_PROBLEM_OPTION_LIST}
              selectedOption={packingProblem.kind}
              handleSelect={(problem) =>
                setProblemDict((prevPackingProblemDict) => ({
                  ...prevPackingProblemDict,
                  [skuId]: {
                    ...prevPackingProblemDict[skuId],
                    kind: problem,
                    directInput: undefined,
                  },
                }))
              }
              width="100%"
            />
          ),
        };

        const textAreaRow: TableDataListItem<ReportProblemTableItem> = {
          rowKey: `${skuId}-direct-input`,

          colSpan: {
            value: 4,
            content: (
              <InputTextArea
                value={packingProblem.directInput}
                setValue={(value) =>
                  setProblemDict((prevPackingProblemDict) => ({
                    ...prevPackingProblemDict,
                    [skuId]: {
                      ...prevPackingProblemDict[skuId],
                      directInput: value,
                    },
                  }))
                }
                placeholder="직접입력"
                isAutoResize
                isValidated={!!packingProblem.directInput?.length}
              />
            ),
            hasFullWidth: true,
          },
        };

        return hasDirectInput
          ? [selectProblemRow, textAreaRow]
          : selectProblemRow;
      }),
    [problemDict, setProblemDict]
  );

  return { problemTableList };
}
