import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import Router from "Router";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { OverlayStackProvider } from "@sellernote/_shared/src/context/overlayStack";
import {
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
  initI18nInstanceForDesignSystem,
} from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { JotaiDevtools } from "@sellernote/_shared/src/services/jotai";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import { printBuildInfo } from "@sellernote/_shared/src/utils/common/etc";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";

const simulateBrowserScan = (barcode: string) => {
  barcode.split("").forEach((v) => {
    document.dispatchEvent(
      new KeyboardEvent("keypress", {
        key: v,
      })
    );
  });
};

const queryClient = new QueryClient(REACT_QUERY_CLIENT_CONFIG);

function App() {
  useEffect(() => {
    printBuildInfo();
  }, []);

  useEffect(() => {
    if (!IS_UNDER_PRODUCTION)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).simulateBrowserScan = simulateBrowserScan;
  }, []);

  const { t } = useTranslation(I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM);
  initI18nInstanceForDesignSystem({ Trans, t });

  return (
    <>
      <OverlayStackProvider>
        <RecoilRoot>
          {IS_READY_FOR_MSW_TOOL && <MSWTool type="web" />}

          <QueryClientProvider client={queryClient}>
            {IS_UNDER_LOCAL_DEVELOPMENT && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}

            <JotaiDevtools>
              <Router>
                <GlobalStyle />
              </Router>
            </JotaiDevtools>
          </QueryClientProvider>
        </RecoilRoot>
      </OverlayStackProvider>
    </>
  );
}

export default App;
