import { useCallback } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

export default function useResetPackingStates() {
  const resetIsWorking = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.IS_WORKING
  );
  const resetInvoiceSummary = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const setOutSidePackage = useSetRecoilState(
    FULFILLMENT_PACKING_ATOMS.OUT_SIDE_PACKAGE
  );
  const resetInvoiceDetailList = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.INVOICE_DETAIL_LIST
  );
  const resetPackingInfoList = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.PACKING_INFO_LIST
  );
  const resetRequestMemoList = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.REQUEST_MEMO_LIST
  );
  const resetSelectedPackingNo = useResetRecoilState(
    FULFILLMENT_PACKING_ATOMS.SELECTED_PACKING_NO
  );

  const resetPackingStates = useCallback(() => {
    resetIsWorking();
    resetInvoiceSummary();
    setOutSidePackage((prevOutSidePackage) => ({
      ...prevOutSidePackage,
      outSidePackageListOfCustomer: [],
      selectedProvider: undefined,
    }));
    resetInvoiceDetailList();
    resetPackingInfoList();
    resetRequestMemoList();
    resetSelectedPackingNo();
  }, [
    resetInvoiceDetailList,
    resetInvoiceSummary,
    resetIsWorking,
    resetPackingInfoList,
    resetRequestMemoList,
    resetSelectedPackingNo,
    setOutSidePackage,
  ]);

  return { resetPackingStates };
}
