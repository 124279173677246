import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { PACKING_ATOMS } from "states/packing";

import { CLASS_OF_MODAL_WITHOUT_BARCODE } from "@sellernote/_shared/src/constants/fulfillment/packing";

const mutationObserverOption: MutationObserverInit = {
  childList: true,
  subtree: true,
};

/**
 * MutationObserver를 사용하여 #app-portal에 열린 모달의 바코드(없는 경우 'modal-type')를 추출
 * Modal을 #app-portal로 관리하지 않게 되면 해당 로직을 변경해야 함
 * TODO: 중첩 모달인 경우 최상위에 있는 모달만 스캔 가능하도록 변경하고 싶으나, Modal 생성 방식을 생성되는 순서대로 쌓이도록 변경해야 될 것으로 보임
 */
export default function useCheckOpensModal() {
  const setOpenedModalValueList = useSetRecoilState(
    PACKING_ATOMS.OPENED_MODAL_VALUE_LIST
  );

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const barcodeList = Array.from(
        document.querySelectorAll(
          "#app-portal img.barcode"
        ) as NodeListOf<HTMLImageElement>
      );
      /**
       * 바코드가 없는 모달인 경우
       * - 'modal-without-barcode'라는 클래스를 추가하여 구분
       * - data attributes 'modal-type'을 바코드 대신 사용 (현재 열린 모달을 구분하기 위함)
       */
      const modalWithoutBarcode = document.querySelector(
        `#app-portal .${CLASS_OF_MODAL_WITHOUT_BARCODE}`
      ) as HTMLDivElement | null;
      const typeOfModalWithoutBarcode = modalWithoutBarcode?.dataset.modalType;

      const modalValueList = (barcodeList ?? []).reduce<string[]>(
        (acc, barcode) => {
          const barcodeValue = barcode.dataset.barcode;

          if (!barcodeValue || acc.includes(barcodeValue)) {
            return acc;
          }

          return [...acc, barcodeValue];
        },
        []
      );
      if (typeOfModalWithoutBarcode) {
        modalValueList.push(typeOfModalWithoutBarcode);
      }

      if (!modalValueList.length) {
        setOpenedModalValueList([]);
        return;
      }

      setOpenedModalValueList(modalValueList);
    });

    observer.observe(
      document.querySelector("#app-portal") as HTMLDivElement,
      mutationObserverOption
    );

    return () => {
      observer.disconnect();

      setOpenedModalValueList([]);
    };
  }, [setOpenedModalValueList]);
}
