import { atom } from "recoil";

import {
  ActionBarcodeType,
  InvoiceItem,
  InvoiceSummaryType,
  OutSidePackage,
  PackingInfoItem,
  PackingLabelData,
  PackingProblemDict,
} from "@sellernote/_shared/src/types/fulfillment/packing";

import { ShippingPacking } from "../../../types/fulfillment/shipping";

const IS_WORKING = atom({
  key: "fulfillment/packing/atoms/IS_WORKING",
  default: false,
});

const CURRENT_ACTION_BARCODE = atom<ActionBarcodeType>({
  key: "fulfillment/packing/atoms/CURRENT_ACTION_BARCODE",
  default: undefined,
});

const INVOICE_SUMMARY = atom<InvoiceSummaryType>({
  key: "fulfillment/packing/atoms/INVOICE_SUMMARY",
  default: {
    companyName: "",
    teamName: "",
    teamId: undefined,
    shippingId: undefined,
    deliveryType: undefined,
  },
});

const OUT_SIDE_PACKAGE = atom<OutSidePackage>({
  key: "fulfillment/packing/atoms/OUT_SIDE_PACKAGE",
  default: {
    selectedProvider: undefined,
    outSidePackageListOfShipda: [],
    outSidePackageListOfCustomer: [],
  },
});

const INVOICE_DETAIL_LIST = atom<InvoiceItem[]>({
  key: "fulfillment/packing/atoms/INVOICE_DETAIL_LIST",
  default: [],
});

const PACKING_INFO_LIST = atom<PackingInfoItem[]>({
  key: "fulfillment/packing/atoms/PACKING_INFO_LIST",
  default: [],
});

const REQUEST_MEMO_LIST = atom<string[]>({
  key: "fulfillment/packing/atoms/REQUEST_MEMO_LIST",
  default: [],
});

const PROBLEM_DICT = atom<PackingProblemDict>({
  key: "fulfillment/packing/atoms/PROBLEM_DICT",
  default: {},
});

const SELECTED_PACKING_NO = atom({
  key: "fulfillment/packing/atoms/SELECTED_PACKING_NO",
  default: 1,
});

const CANCELED_INVOICE_NO = atom({
  key: "fulfillment/packing/atoms/CANCELED_INVOICE_NO",
  default: "",
});

/** 상품 하나의 스캔이 끝날 때마다 취소여부를 확인하기 위한 상태 */
const IS_SCANNED_SKU_ID_COMPLETE = atom({
  key: "fulfillment/packing/atoms/IS_SCANNED_SKU_ID_COMPLETE",
  default: false,
});

/** 패킹 완료된 송장을 스캔한 경우 - 패킹 내역 조회, 송장(QR)수동 출력, 패킹 라벨 출력이 가능한 상태 */
const IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE = atom({
  key: "fulfillment/packing/atoms/IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE",
  default: false,
});

/** 패킹내역 조회 및 송장(QR)수동 출력 상태인 것을 작업자에게 알려주기 위한 토스트를 관리하는 상태 */
const IS_COMPLETED_INVOICE = atom({
  key: "fulfillment/packing/atoms/IS_COMPLETED_INVOICE",
  default: false,
});

/**
 * 송장(QR)수동 출력 화면을 위해 포장 정보를 저장하기 위한 상태
 * 송장 출력 시에도 해당 정보를 사용
 */
const PACKINGS = atom<ShippingPacking[]>({
  key: "fulfillment/packing/atoms/PACKINGS",
  default: [],
});

/**
 * 포장 마감 -> 송장 출력 후 패킹 리스트 출력 여부를 물어봐야하는 경우
 * 출력 후에는 다시 false로 만들어서 작업 대기 상태로 돌아갈 수 있도록 함
 */
const IS_NEED_TO_CHECK_PRINT_PACKING_LABEL = atom({
  key: "fulfillment/packing/atoms/IS_NEED_TO_CHECK_PRINT_PACKING_LABEL",
  default: false,
});

/**
 * 패킹 라벨지에 필요한 정보를 저장하기 위한 상태
 */
const DATA_FOR_PACKING_LABEL = atom<
  | Pick<PackingLabelData, "orderNo" | "customerName" | "customerPhone">
  | undefined
>({
  key: "fulfillment/packing/atoms/DATA_FOR_PACKING_LABEL",
  default: undefined,
});

export default {
  IS_WORKING,

  CURRENT_ACTION_BARCODE,

  INVOICE_SUMMARY,
  OUT_SIDE_PACKAGE,
  INVOICE_DETAIL_LIST,
  PACKING_INFO_LIST,
  REQUEST_MEMO_LIST,

  PROBLEM_DICT,

  SELECTED_PACKING_NO,
  CANCELED_INVOICE_NO,
  IS_SCANNED_SKU_ID_COMPLETE,
  IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE,
  IS_COMPLETED_INVOICE,
  PACKINGS,

  IS_NEED_TO_CHECK_PRINT_PACKING_LABEL,
  DATA_FOR_PACKING_LABEL,
};
