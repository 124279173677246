import { useCallback } from "react";
import { useSetRecoilState } from "recoil";

import { FULFILLMENT_AUTH_ATOMS } from "@sellernote/_shared/src/states/fulfillment/auth";

export default function useLogout() {
  const setLoggedIn = useSetRecoilState(FULFILLMENT_AUTH_ATOMS.LOGGED_IN);

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/";
  }, [setLoggedIn]);

  return {
    handleLogout,
  };
}
