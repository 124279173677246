import { useRecoilValue } from "recoil";

import {
  FULFILLMENT_PACKING_ATOMS,
  FULFILLMENT_PACKING_SELECTORS,
} from "@sellernote/_shared/src/states/fulfillment/packing";

export default function useInvoiceSummary() {
  const { shippingId, teamId, companyName, teamName } = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.INVOICE_SUMMARY
  );
  const invoiceNoOfSelectedPackingNo = useRecoilValue(
    FULFILLMENT_PACKING_SELECTORS.INVOICE_NO_OF_SELECTED_PACKING_NO
  );

  return {
    invoiceNoOfSelectedPackingNo,
    customer: !!shippingId && `${companyName} (${teamName} / ${teamId})`,
    shippingId,
  };
}
