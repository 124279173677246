import { SetterOrUpdater } from "recoil";

import { InputSelectOption } from "@sellernote/_shared/src/headlessComponents/input/useInputSelect";

import {
  InvoiceItem,
  OutSidePackageProviderValue,
  PackingDetailItem,
  PackingInfoItem,
  PackingItem,
  PackingPackage,
} from "../../types/fulfillment/packing";

/**
 * PackingDetail 삭제 시(삭제 버튼, 최근포장삭제) InvoiceDetailList의 수량, 포장번호를 되돌린다.
 *
 * @param packingDetailList - PackingDetail 리스트
 * @param skuIdListToDelete - 삭제할 SKU ID들의 리스트
 * @param setInvoiceDetailList - InvoiceDetailList를 업데이트하는 함수
 * @returns void
 */
export const restoreInvoiceDetailList = ({
  packingDetailList,
  skuIdListToDelete,
  setInvoiceDetailList,
}: {
  packingDetailList: PackingDetailItem[];
  skuIdListToDelete: number[];
  setInvoiceDetailList: SetterOrUpdater<InvoiceItem[]>;
}) => {
  setInvoiceDetailList((prevInvoiceDetailList) =>
    prevInvoiceDetailList.map((invoiceDetailItem) => {
      const isSkuIdToDelete = skuIdListToDelete.includes(
        invoiceDetailItem.skuId
      );
      if (isSkuIdToDelete) {
        const packingNoList = invoiceDetailItem.packingNo.split(", ");
        const hasOnlyOnePackingNo = packingNoList.length === 1;
        const currentQtyOfSkuIdToDelete =
          getDetailOfSku({
            list: packingDetailList,
            skuId: invoiceDetailItem.skuId,
          })?.currentQty ?? 0;

        return {
          ...invoiceDetailItem,
          packingNo: hasOnlyOnePackingNo
            ? ""
            : packingNoList.slice(0, -1).join(", "),
          currentQty: invoiceDetailItem.currentQty - currentQtyOfSkuIdToDelete,
        };
      }

      return invoiceDetailItem;
    })
  );
};

/**
 * 해당 SKU ID의 이전에 스캔된 총 수량 구하기
 *
 * @param packingInfoList - 포장 정보 리스트
 * @param skuId - SKU ID
 * @param skuBarcode - SKU 바코드 (선택 사항)
 * @param isDeleteLastPacking - 최근 포장 삭제 여부 (선택 사항)
 * @returns 이전에 스캔된 총 수량
 */
export const getPrevTotalQuantity = ({
  packingInfoList,
  skuId,
  skuBarcode,
  isDeleteLastPacking,
}: {
  packingInfoList: PackingInfoItem[];
  skuId: number | undefined;
  skuBarcode?: string;
  isDeleteLastPacking?: boolean; // isDeleteLastPacking(최근포장삭제)인 경우 마지막 포장은 삭제되어 없어지므로 -2를 해준다.
}) => {
  const lastIndexToSlice = isDeleteLastPacking ? -2 : -1;

  const prevTotalQuantity = packingInfoList
    .map((packingInfo) => packingInfo.packingDetailList)
    .slice(0, lastIndexToSlice)
    .flat()
    .filter(
      (prevPackingDetailItem) =>
        prevPackingDetailItem.skuId === skuId ||
        prevPackingDetailItem.skuBarcode === skuBarcode
    )
    .reduce((prevTotal, item) => prevTotal + item.quantity, 0);

  return prevTotalQuantity;
};

/**
 * 마지막 PackingNo 가져오기
 *
 * @param packingInfoList - 포장 정보 리스트
 * @returns 마지막 PackingNo
 */
export const getLastPackingNo = (packingInfoList: PackingInfoItem[]) =>
  packingInfoList.length;

/**
 * PackingNo 또는 'last'에 해당하는 PackingInfo 가져오기
 * 'last'인 경우 마지막 PackingInfo는 기본값으로 존재하기 때문에 undefined가 될 수 없다.
 * selectors에서 사용할 경우에는 처음 실행 시 undefined이므로 옵셔널 연산자를 붙여야 한다.
 *
 * @param packingInfoList - 포장 정보 리스트
 * @param packingNo - 포장 번호 또는 'last'
 * @returns 해당 포장 번호의 PackingInfo 또는 'last'의 경우 마지막 PackingInfo
 */
export const getPackingInfoOfPackingNo = <PackingNo extends number | "last">({
  packingInfoList,
  packingNo,
}: {
  packingInfoList: PackingInfoItem[];
  packingNo: PackingNo;
}): PackingNo extends number ? PackingInfoItem | undefined : PackingInfoItem =>
  typeof packingNo === "number"
    ? packingInfoList[packingNo - 1]
    : packingInfoList[getLastPackingNo(packingInfoList) - 1];

/**
 * InvoiceDetail 또는 PackingDetail 리스트에서 해당하는 SKU ID의 정보를 가져오기
 *
 * @param list - InvoiceDetail 또는 PackingDetail 리스트
 * @param skuId - SKU ID (선택 사항)
 * @param skuBarcode - SKU 바코드 (선택 사항)
 * @returns 해당 SKU ID 또는 바코드의 정보
 */
export const getDetailOfSku = <
  ListType extends InvoiceItem | PackingDetailItem
>({
  list,
  skuId,
  skuBarcode,
}: {
  list?: ListType[];
  skuId?: number;
  skuBarcode?: string;
}): ListType | undefined => {
  if (!list) return;

  return list.find(
    (item) => item.skuId === skuId || item.skuBarcode === skuBarcode
  );
};

/**
 * 외포장재 리스트 만들기
 *
 * @param list - 포장 패키지 리스트
 * @returns 외포장재 리스트
 */
export const getOutSidePackageList = (list: PackingPackage[]) =>
  list.map((item) => ({
    label: item.name ?? "-",
    value: item.id,
  }));

/**
 * 외포장재 리스트 정렬을 위한 유틸함수
 *
 * @param list - 포장 패키지 리스트
 * @returns 정렬된 외포장재 리스트
 */
export const sortOutSidePackageList = (list: readonly PackingPackage[]) =>
  [...list].sort((a, b) => {
    const packageA = a.outerType;
    const packageB = b.outerType;

    // 외포장재에는 outerType이 존재함
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return packageA!.localeCompare(packageB!, "en", { numeric: true });
  });

/**
 * InvoiceDetail의 리스트를 정렬하기 위한 유틸함수
 *
 * @param data - InvoiceDetail 리스트
 * @returns 정렬된 InvoiceDetail 리스트
 */
export const sortListByItemNameAndSkuId = (data: readonly InvoiceItem[]) =>
  [...data].sort(
    (a, b) => a.itemName.localeCompare(b.itemName) || a.skuId - b.skuId
  );

export const providerOptionDict: {
  [key in OutSidePackageProviderValue]: InputSelectOption<OutSidePackageProviderValue>;
} = {
  shipda: { label: "쉽다", value: "shipda" },
  customer: { label: "고객사", value: "customer" },
  none: { label: "포장불필요", value: "none" },
};

/**
 * 패킹 item에서 완충재, 테이프 정보를 반환
 *
 * @param packingItem - 패킹 아이템
 * @returns 완충재 리스트와 테이프 정보
 */
export const getBufferAndTapePackage = (
  packingItem: PackingItem | undefined
) => {
  if (!packingItem) return { bufferPackageList: [], tapePackage: "" };

  const isNonePackageType = packingItem.sku.materialPackageType === "none";

  const bufferPackageList = isNonePackageType
    ? ["포장불필요"]
    : (packingItem.sku.packages ?? [])
        .filter(
          (packageItem) =>
            // 완충재는 [쉽다웹 > 입고관리 > 상품등록/수정]에서 '필요 없음' 선택이 가능하므로 'none'의 경우도 고려
            packageItem.packageCategory === "buffer" ||
            packageItem.packageCategory === "none"
        )
        // 초기 기획은 상품 하나에 완충재가 여러 개 일 수 있어서 string[]로 했으나, 수정된 기획에서는 1개만 등록이 가능 -> 추후 추가될 가능성이 있어서 그대로 두기로 결정
        .map((packageItem) => packageItem.name);

  const tapePackage = isNonePackageType
    ? "포장불필요"
    : packingItem.sku.packages.find(
        (packageItem) => packageItem.packageCategory === "tape"
      )?.name ?? "";

  return {
    bufferPackageList,
    tapePackage,
  };
};

/**
 * 포장 마감 -> 송장 출력 후 패킹 리스트 출력 여부를 물어봐야하는 teamId 목록
 */
export const listOfCustomersWhoNeedToCheckPackingLabelPrinting = new Map([]);

/**
 * 스캔한 상품 바코드가 동일한 상품이 있는 InvoiceDetail 리스트
 *
 * @param invoiceDetailList - InvoiceDetail 리스트
 * @param scannedBarcode - 스캔한 상품 바코드
 * @returns 동일한 바코드를 가진 InvoiceDetail 리스트
 */
export const getInvoiceDetailListOfDuplicateBarcode = ({
  invoiceDetailList,
  scannedBarcode,
}: {
  invoiceDetailList: InvoiceItem[];
  scannedBarcode: string;
}) => invoiceDetailList.filter((item) => item.skuBarcode === scannedBarcode);
