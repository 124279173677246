import Styled from "./index.styles";

export default function VideoRecording({ className }: { className?: string }) {
  return (
    <Styled.container>
      <div className="title">동영상 촬영대기</div>
      <div className="video"></div>
    </Styled.container>
  );
}
