import styled from "styled-components";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/_shared/src/stylesToMoveToV1/typography";

const container = styled.div`
  height: 100vh;
`;

const mainContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;

  > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    > .title {
      ${setFontStyle("Head4", "Bold")};
      color: ${COLOR.grayScale_700};
    }
  }

  > .body {
    display: flex;

    > .info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
    }

    > .action {
      position: relative;
      flex-shrink: 0;
      width: 240px;
      display: flex;
      flex-direction: column;

      > .app-build-info {
        > .built-at {
          position: absolute;
          right: 0;
          bottom: -24px;
          font-size: 12px;
          color: ${COLOR.bk_60};
        }

        > .hidden {
          display: none;
        }
      }
    }
  }
`;

const invoiceInfoContainer = styled.div`
  display: flex;

  > .summary {
    flex-shrink: 0;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;

    > .direct-input-button {
      align-self: flex-end;
      padding: 0 8px;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: -0.6px;
    }
  }

  > .detail {
    flex-grow: 1;
    /* 테이블이 깨지는 것을 막기 위해서 임시로 min-width 지정 */
    min-width: 500px;
    display: flex;
    flex-direction: column;
  }
`;

const canceledInvoiceModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > .description {
    margin-bottom: 24px;
    ${setFontStyle("Body6")};
    color: ${COLOR.grayScale_700};

    > strong {
      color: ${COLOR.pointWarning};
    }
  }

  > .warning-message {
    margin-bottom: 32px;
    ${setFontStyle("Body7")}
    color: ${COLOR.pointWarning};
  }

  > .barcode-action {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      height: 56px;
    }
  }
`;

export default {
  mainContainer,
  invoiceInfoContainer,
  canceledInvoiceModalBody,
  container,
};
