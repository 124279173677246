import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import { FULFILLMENT_PACKING_ATOMS } from "@sellernote/_shared/src/states/fulfillment/packing";

export default function PrintPackingLabel() {
  const isStatusOfScanningCompletedInvoice = useRecoilValue(
    FULFILLMENT_PACKING_ATOMS.IS_STATUS_FOR_SCANNING_COMPLETED_INVOICE
  );

  const history = useHistory();

  const handlePrintPackingLabelClick = useCallback(() => {
    history.push("/packing/print");
  }, [history]);

  return (
    <Button
      theme="tertiary"
      size="normal"
      label="패킹 라벨 출력"
      handleClick={handlePrintPackingLabelClick}
      disabled={!isStatusOfScanningCompletedInvoice}
    />
  );
}
